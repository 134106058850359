//HEADER--------------------------------------------
.header {
  padding: 16px 0px 15px 0px;
  background: #ffffff;
  color: #000;
  position: relative;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  a {
    color: #000;
    font-family: $fontSecond;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.15;
    letter-spacing: 0.13em;
    transition: opacity 0.8s ease 0s;

    &:hover {
      opacity: 0.5;
    }
  }

  &__inner {
    padding: 0px 25px;
    margin: 0 auto;

    @media (min-width: 1440px) {
      padding: 0px 35px;
    }
  }
}

.inner-header {
  &__nav {
    display: flex;
    align-items: center;

    @media (max-width: 604px) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__logo {
    display: inline-block;
    margin: 0px 34px 10px 0px;

    svg {
      width: 34px;
      height: 34px;
    }

    @media (max-width: 992px) {
      margin: 0px auto 0px auto;
      padding-right: 17px;
      position: relative;
      z-index: 100;
    }
  }

  &__hamburger {
    display: none;
    height: 15px;
    width: 17px;
    position: relative;
    z-index: 10;
    margin: 0px 0px 0px -10px;

    @media (max-width: 992px) {
      display: block;
    }

    &--span {
      top: 0;
      bottom: 0;
      margin: auto;
    }

    span {
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 100%;
      background: $ColorMain;
      left: 0;
      transition: all 0.4s ease 0s;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    &.active {
      .inner-header__hamburger--span {
        opacity: 0;
      }

      span {
        background: $ColorBlack;

        &:first-child {
          transform: rotate(45deg);
          bottom: 0;
          margin: auto;
        }

        &:last-child {
          transform: rotate(-45deg);
          top: 0;
          margin: auto;
        }
      }
    }
  }

  &__menu {
    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    @media (max-width: 992px) {
      position: fixed;
      top: 0;
      left: 0;
      height: min-content;
      width: 100%;
      background: $ColorWhite;
      transition: all 0.6s ease-in-out 0s;
      transform: translate(0%, -150%);
      flex-direction: column;
      align-items: center;
      padding: 60px 0px 0px;
      z-index: 9;

      &.open {
        transform: translate(0%, 0%);
      }
    }
  }

  &__list {
    display: flex;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }

    li {
      &:not(:last-child) {
        @media (min-width: 992px) {
          margin: 0px 20px 0px 0px;
        }
      }

      @media (max-width: 992px) {
        margin: 0;
        font-size: 14px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: block;
        width: 100%;
        padding: 20px 10px;

        a {
          padding: 0 20px;
        }
      }
    }
  }

  &__login {
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    a {
      display: inline-block;
      @media (max-width: 992px) {
        margin: 0px 0px 0px 0px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: block;
        width: 100%;
        padding: 20px 0px 20px 40px;
      }
    }
  }

  &__trial {
    margin: 0px 20px 0px 0px;
    font-weight: 900 !important;

    @media (max-width: 992px) {
      margin: 0px;
    }
  }
}
