//VARS---------------------------------
//WIDTH CONTAINER
$maxWidthContainer: 920px;
$widthPadding: 25px;
$maxWidthAll: $maxWidthContainer + $widthPadding * 2;
$maxWidth: 1440;

//FONT NAME
$fontFirst: "AkkuratPro", sans-serif;
$fontSecond: "Brandon Groteskue", sans-serif;

//FONT SIZE
$SizeMain: 16px;

//COLOR
$ColorMain: #999999;
$ColorBlack: #000000;
$ColorWhite: #ffffff;
$ColorGreen: #64e8be;
$GreenHover: #3c8f75;

//ADAPTIVE FONT (computed of font size in depending on screen width)
@mixin adaptiv-font($pcSize, $mobSize) {
  $addSize: $pcSize - $mobSize;
  $maxWidth: $maxWidth - 320;
  font-size: calc(
    #{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth})
  );
}
