//FONTS-----------------------------
@font-face {
  font-family: "Brandon Groteskue";
  src: url("../fonts/brandon_med.eot");
  src: local("☺"), url("../fonts/brandon_med.woff") format("woff"),
    url("../fonts/brandon_med.ttf") format("truetype"),
    url("../fonts/brandon_med.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Brandon Groteskue";
  src: url("../fonts/brandon.eot");
  src: local("☺"), url("../fonts/brandon.woff") format("woff"),
    url("../fonts/brandon.ttf") format("truetype"),
    url("../fonts/brandon.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "AkkuratPro";
  src: url("../fonts/AkkuratPro-Regular.eot");
  src: local("☺"), url("../fonts/AkkuratPro-Regular.woff") format("woff"),
    url("../fonts/AkkuratPro-Regular.ttf") format("truetype"),
    url("../fonts/AkkuratPro-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "AkkuratPro";
  src: url("../fonts/AkkuratPro-Bold.eot");
  src: local("☺"), url("../fonts/AkkuratPro-Bold.woff") format("woff"),
    url("../fonts/AkkuratPro-Bold.ttf") format("truetype"),
    url("../fonts/AkkuratPro-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
