//WELCOME---------------------------------------
.welcome {
  position: relative;
  width: 100%;
  padding: 0px 0px 25% 0px;
  min-height: 460px;
  margin: 79px 0px 0px;

  @media (max-width:1441px) {
    padding: 0px 0px 12% 0px;
  }


  &:after {
    content: "";
    position: absolute;
    width: 130%;
    height: 120%;

    top: -19%;
    left: -20%;
    right: 0%;
    bottom: 0;
    box-shadow: inset 0px -40px 120px 120px #000;
    z-index: 5;

    @media (max-width: 992px) {
      width: 140%;
    }

    @media (max-width: 600px) {
      width: 200%;
      left: -40%;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;

      @media (min-width: 769px) {
        display: none;
      }
    }
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;

      @media (max-width: 769px) {
        display: none;
      }
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
  }
  &__text {
    position: absolute;
    bottom: 20px;
    z-index: 10;
  }

  &__title {
    margin: 0px;
    font-weight: 700;
    @include adaptiv-font(90, 48);
    line-height: 1;
    color: $ColorWhite;
    margin: 0px 0px -20px;
  }

  &__subtitle {
    position: relative;
    z-index: 1;
    @include adaptiv-font(39, 22);
  }
}
//WE---------------------------------------
.faq {
  position: relative;
  background: url(../../img/background-faq.png) top -2% right 0 no-repeat;
  background-size: contain;
  padding: 92px 0px 130px;
  z-index: 1;

  @media (max-width: 768px) {
    background: none;
    padding: 53px 0px 0px;
  }
}

.block-faq {
  margin: 0px 0px 50px;
  @media (max-width: 768px) {
  }

  &__title {
    font-weight: 700;
    @include adaptiv-font(30, 24);
    line-height: 1.25;
    color: $ColorGreen;
    margin: 0px 0px 10px;
    max-width: 920px;
    position: relative;
    padding: 0px 60px 0px 0px;

    &:after {
      content: "";
      background: url(../../img/arrow-bottom.svg) no-repeat;
      width: 22px;
      height: 12px;
      cursor: pointer;
      position: absolute;
      transition: all 0.4s ease 0s;
      top: 10px;
      right: 10px;
      transform: rotate(360deg);

      @media (min-width: 768px) {
        display: none;
      }
    }

    &.active {
      &:after {
        background: url(../../img/arrow-bottom-green.svg) no-repeat;
        transform: rotate(0deg);
      }
    }
  }

  &__text {
    font-weight: 400;
    @include adaptiv-font(24, 18);
    line-height: 1.33;
    color: $ColorWhite;
    max-width: 760px;
    overflow: hidden;
    transition: all 0.5s ease-out 0s;

    &.active {
      max-height: 500px;
    }

    span {
      line-height: 0.97;
      border-bottom: 1px solid $ColorWhite;
    }

    a {
      font-weight: 400;
      @include adaptiv-font(24, 18);
      line-height: 1.33;
      color: $ColorGreen;
      transition: all 0.5s ease 0s;
      border-bottom: 1px solid $ColorGreen;

      &:hover {
        color: $GreenHover;
        border-bottom: 1px solid $GreenHover;
      }
    }

    @media (max-width: 768px) {
      max-height: 0px;
    }
  }

  &__link {
    display: inline-block;
    font-weight: bold;
    @include adaptiv-font(24, 18);
    line-height: 0.97;
    color: $ColorGreen;
    transition: all 0.5s ease 0s;
    border-bottom: 1px solid $ColorGreen;

    &:hover {
      color: $GreenHover;
      border-bottom: 1px solid $GreenHover;
    }
  }
}
