//MAIN FONT
body,
input,
button,
textarea,
a,
button,
ul,
li {
  font-family: $fontFirst;
  color: $ColorMain;
  background: transparent;
  font-size: $SizeMain;
  font-weight: 500;
  text-decoration: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  list-style: none;
}

//Containers
.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: $ColorBlack;
}

.--container {
  max-width: $maxWidthAll;
  margin: 0 auto;
  padding: 0px $widthPadding;
}

//ALL_STYLE-----------------------------------

.title {
  font-weight: 700;
  @include adaptiv-font(76, 48);
  line-height: 1.1;
  color: $ColorWhite;
}

.white-subtitle {
  font-weight: 700;
  @include adaptiv-font(27, 22);
  line-height: 1.25;
  color: $ColorWhite;
}
